import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { throwError } from "rxjs/internal/observable/throwError";
import { Company } from "../models/company.model";
import { Observable } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { BroadcastMessage } from "../models/broadcast-message.model";
import { Feature } from "../models/feature.model";
import { FeatureLink } from "../models/feature-link.model";
import { CompanyFeature } from "../models/company-feature.model";
import { CompanyFeatureStatus } from "../models/company-feature-status.model";
import { User } from "../models/user.model";


@Injectable({
  providedIn: "root",
})
export class CompanyService {
  baseUrl = environment.apiserver;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private httpClient: HttpClient) {}

  getAllcompanies(data?: any): Observable<Company[]> {
    let url = `${this.baseUrl}/allcompanies?page=1`;

    if (data > 0) {
      url = `${this.baseUrl}/allcompanies?page=` + data;
    }

    return this.httpClient
      .get<Company[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  listAllcompanies(): Observable<Company[]> {
    let url = `${this.baseUrl}/company/listall`;

    return this.httpClient.get<Company[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveCompany(data: any): Observable<Company> {
    let url = `${this.baseUrl}/company/create`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/company/update/` + data["id"];
    }

    return this.httpClient
      .post<Company>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCompany(id: number): Observable<Company> {
    let url = `${this.baseUrl}/company/` + id;

    return this.httpClient
      .get<Company>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  listFactoryTestUsersByCompany(id: number): Observable<User[]> {
    if (id > 0) {
      let url = `${this.baseUrl}/users/lab/testers/` + id;
    
      return this.httpClient
        .get<User[]>(url)
        .pipe(retry(1), catchError(this.handleError));
    }
  }

  listCompanyUsersExceptSuperAdmin(id: number): Observable<User[]> {
    if (id > 0) {
      let url = `${this.baseUrl}/users/drop/company/` + id;
    
      return this.httpClient
        .get<User[]>(url)
        .pipe(retry(1), catchError(this.handleError));
    }
  }

  listFactoryUsersExceptSuperAdmin(id: number): Observable<User[]> {
    if (id > 0) {
      let url = `${this.baseUrl}/users/drop/factlab/` + id;
    
      return this.httpClient
        .get<User[]>(url)
        .pipe(retry(1), catchError(this.handleError));
    }
  }

  saveBroadcastMessage(data: any): Observable<BroadcastMessage> {
    debugger;
    let url = `${this.baseUrl}/broadcast/create`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/broadcast/update/` + data["id"];
    }

    return this.httpClient
      .post<BroadcastMessage>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveCompanyFeature(data: any): Observable<CompanyFeature> {
    let url = `${this.baseUrl}/featureCompany/create`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/featureCompany/update/` + data["id"];
    }

    return this.httpClient
      .post<CompanyFeature>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllBroadcastMsgs(page?: number): Observable<BroadcastMessage[]> {
    let url = `${this.baseUrl}/broadcast/all?page=1`;

    if (page > 0) {
      url = `${this.baseUrl}/broadcast/all?page=` + page;
    }

    return this.httpClient.get<BroadcastMessage[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getNotices(): Observable<BroadcastMessage[]> {
    let url = `${this.baseUrl}/broadcast/active/all`;

    return this.httpClient.get<BroadcastMessage[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  searchCompanyUsersByName(value: string): Observable<User[]> {
    let url = `${this.baseUrl}/users/company/search?query=` + value;

    return this.httpClient
      .get<User[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  // searchPlantsByName(value: string): Observable<Factory[]> {
  //   let url = `${this.baseUrl}/plants/company/search?query=` + value;

  //   return this.httpClient
  //     .get<Factory[]>(url)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  getAllFeatures(): Observable<Feature[]> {
    let url = `${this.baseUrl}/feature/getAll`;

    return this.httpClient.get<Feature[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getCompanyFeatures(id: number): Observable<CompanyFeature[]> {
    let url = `${this.baseUrl}/feature/company/`+id;

    return this.httpClient.get<CompanyFeature[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getCompanyFeaturesByRole(id: number, role:number): Observable<any[]> {
    let url = `${this.baseUrl}/feature/company/`+id+`/`+role;

    return this.httpClient.get<any[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getCompanyFeatureStatus(): Observable<CompanyFeatureStatus[]> {
    let url = `${this.baseUrl}/feature/companyStatus/all`;

    return this.httpClient.get<CompanyFeatureStatus[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllFeatureLinksByFeatureId(id: number): Observable<FeatureLink[]> {
    let url = `${this.baseUrl}/feature/featurelink/`+id;

    return this.httpClient.get<FeatureLink[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  saveFeature(data: any): Observable<Feature> {
    debugger;
    let url = `${this.baseUrl}/feature/create`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/feature/update/` + data["id"];
    }

    return this.httpClient
      .post<Feature>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }
  
  saveFeatureLink(data: any): Observable<FeatureLink> {
    debugger;
    let url = `${this.baseUrl}/featureLink/create`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/featureLink/update/` + data["id"];
    }

    return this.httpClient
      .post<FeatureLink>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
