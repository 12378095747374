import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { User } from "src/app/shared/models/user.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { enableProdMode } from "@angular/core";
import { MessageService } from "primeng/api";
import { HttpClient } from "@angular/common/http";

enableProdMode();

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  newForm: FormGroup;
  username: string;
  password: string;
  isWaiting: boolean = false;
  userSession: User;
  loadPopup: boolean = false;
  msgs: Message[] = [];
  submitted: boolean;
  meta: any;
  userList: User[] = [];

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private _router: Router,
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.isWaiting = false;
    this.msgs = [];
    this.buildForm();
    this.getUser();

    //getting current user
    // this.authService.user.subscribe(data => {
    //   this.userSession = data;
    // });
  }


  validateUser() {
    this.isWaiting = true; 
    
    
    if (this.newForm.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please fill required fields!",
      });

      return false;
    }

    var credentials = {
      email: this.newForm.get("email").value,
      password: this.newForm.get("password").value,
    };

    this.loadPopup = true;
    this.messageService.clear();

    this.authService.validateUser(this.newForm.value).subscribe(
      (res) => {
        this.isWaiting = false;

        this.authService.updatedDataSelection({
          id: res["id"],
          name: res["name"],
          email: res["email"],
          password: "",
          image: res["image"],
          level: res["level"],
          factory_id: res["factory_id"],
          role: res["role"],
          contact: res["contact"],
          token: res["access_token"],
          status: res["status"],
          sign_img: res["sign_img"],
          profile: res["profile"],
          factory: res["factory"],
          tokenexpire: moment().add(JSON.parse(res["expires_in"]), "second"),
        });

        this.authService.waiting.subscribe((data) => {
          this.loadPopup = data;
          // console.log(this.userSession);          
        });

        this.getUserSession();
        this.buildForm();

        if(this.userSession.status == "2"){
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Inactive User!",
          });
          this._router.navigate(["/"]);
        }
      },
      
      (err) => {        
        this.isWaiting = false;
        this.authService.waiting.subscribe((data) => {
          this.loadPopup = data;
        });

        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Username or password is incorrect!",
        });
      }      
    );
  }

  getUserSession() {
    this.authService.user.subscribe((data) => {
      this.userSession = data;
      // console.log(this.userSession);

      this._router.navigate(["/base/dashboard"]);
      // if (this.userSession.level == "0" || this.userSession.level == "1" || this.userSession.level == "2" || this.userSession.level == "3") {
      //   this._router.navigate(["/base/dashboard-c-labs"]);
      // } 
      // else if (this.userSession.level == "3") {
      //   this._router.navigate(["/base/dashboard/all-request"]);
      // }
      // else {
      //   this._router.navigate(["/base/dashboard/main"]);
      // }
    });
  }
  
  buildForm() {
    //form group for register new custormer
    this.newForm = this.fb.group({
      id: [""],
      name: ["0"],
      email: ["", Validators.required],
      password: ["", Validators.required],
      image: [""],
      role: ["0"],
      contact: ["0"],
      status: ["1"],
    });
  }

  // Submit(value: string) {debugger;
  //   if (this.newForm.invalid) {
  //     this.messageService.add({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "Please fill required fields!",
  //     });
  //     return false;
  //   }

  //   this.authService.validateUser(this.newForm.value).subscribe((res) => {
  //     var tempId = res["data"]["id"];
  //     this.getUser();
  //     this.messageService.add({
  //       severity: "success",
  //       summary: "Success",
  //       detail: "successfully!",
  //     });

  //     this._router.navigate(["/base/" + res["data"]["id"]]);
  //   }, error =>{
  //     this.messageService.add({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "Please fill required fields!",
  //     });
  //   });
  //   this.submitted = true;
  // }
  getUser(id?, page?) {
    // this.authService.getUser(id , page).subscribe(res =>{
    //   this.userList = res['data'];
    //   this.meta = res['meta'];
    // });
  }
}
