import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from "./app.component";

// Import containers
//import { DefaultLayoutComponent } from './containers';

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
//import { LoginComponent } from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';

//crm

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

const APP_CONTAINERS = [
  //DefaultLayoutComponent
];

// Import routing module
import { AppRoutingModule } from "./app-routing.module";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
//import { ChartsModule } from 'ng2-charts';
import { LoginModule } from "./modules/login/login.module";
//import { RegisterModule } from './views/register/register.module';

import { AuthInterceptor } from "./shared/core/AuthInterceptor";

import { ComponentModule } from "./modules/component/component.module";


import { ProgressBarModule } from "primeng/progressbar";
import { ButtonModule } from "primeng/button";



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    //ChartsModule,
    HttpClientModule,
    LoginModule,
    ComponentModule,
    
    ProgressBarModule,
    ButtonModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,

    //RegisterComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],

  bootstrap: [AppComponent],
  entryComponents: [
    
  ],
})
export class AppModule {}
