import * as moment from "moment";
import { Employee } from "./Employee.model";
import { Factory } from "./factory.model";

export class User {
  public id: number;
  public name: string;
  public email: string;
  public password: string;
  public image: string;
  public level: string;
  public status: string;
  public sign_img: string;
  public factory_id: number;
  public role: string;
  public contact: string;
  public profile?: Employee;
  public factory?: Factory;
  public token: string;
  public tokenexpire: moment.Moment;
}
