// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // baseurl: "http://localhost:4200/",                                           //local link
   baseurl: "https://unicoan.com/robot_be/",                                  //live server link

  // imageServer: "http://localhost:8000/storage/",                               //local link
   imageServer: "https://unicoan.com/robot_be/public/storage/",               //live server link

  // apiserver: "http://localhost:8000/api",                                      //local link
   apiserver: "https://unicoan.com/robot_be/public/api",                      //live server link

  // server: "http://localhost:8000/",                                            //local link
   server: "https://unicoan.com/robot_be/public/",                            //live server link
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
